@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Inter&family=Nanum+Brush+Script&family=Poppins&display=swap');
@import url('https://fonts.cdnfonts.com/css/dot-matrix');

* {
    box-sizing: border-box;
    font-family: 'Poppins';
    padding: 0;
    margin: 0;
}

.MuiAutocomplete-noOptions {
    display: none;
}

.printable {
    display: none;
}

@media print {
    @page {
        size: A4;
        margin: 0;
        padding: 0;
    }

    body * {
        visibility: hidden;
    }

    .printable,
    .printable * {
        visibility: visible;
    }

    .styledPaper {
        display: none;
    }

    .modal {
        display: none;
    }

    .header {
        display: none;
    }

    .leftPanel {
        display: none;
    }

    .printable {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        background-color: white;
        display: block;
        z-index: 100;
        margin: 0;
        padding: 0;
    }
}
